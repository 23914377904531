// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "./dasboard.css";

// export default function Sidebar({ sidebarActive, toggleSidebar }) {
//   const [role, setRole] = useState("");
//   const [userID, setUserID] = useState("");
//   const [name, setName] = useState("");

//   useEffect(() => {
//     let local_role = localStorage.getItem("role");
//     let id = localStorage.getItem("userId");
//     let name = localStorage.getItem("name");
//     setRole(local_role);
//     setUserID(id);
//     setName(name);
//   }, []);

//   console.log(userID, "userID");
//   return (
//     <>
//       <nav id="sidebar" className={` ${sidebarActive ? "active" : ""}`}>
//         <div className="sidebar-header">
//           <h3 style={{ fontWeight: "bolder" }}>
//             <a href="/">EP Desk</a>
//           </h3>
//         </div>

//         <ul className="list-unstyled components">
//           {/* <li className="dashboard">
//             <a href="/">Dashboard</a>
//           </li> */}
//           <li className="">
//             <a
//               href="#homeSubmenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               News Realms (category)
//               {/* Category */}
//             </a>
//             <ul className="collapse list-unstyled" id="homeSubmenu">
//               <li>
//                 <Link to="/addcategory">Add Category</Link>
//               </li>
//               <li>
//                 <Link to="/managecategory">Manage Category</Link>
//               </li>
//             </ul>
//           </li>

//           <li>
//             <a
//               href="#subCatSubmenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               Focus Zones(sub_category)
//               {/* Sub Category  */}
//             </a>
//             <ul className="collapse list-unstyled" id="subCatSubmenu">
//               <li>
//                 <Link to="/addsubcategory">Add Sub Category</Link>
//               </li>
//               <li>
//                 <Link to="/managesubcategory">Manage Sub Category</Link>
//               </li>
//             </ul>
//           </li>

//           <li>
//             <a
//               href="#secondarysubcategorySubmenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >

//               Deep Dives(secondarysubcategory) 
//               {/* (Secondary Sub Category) */}
//             </a>
//             <ul
//               className="collapse list-unstyled"
//               id="secondarysubcategorySubmenu"
//             >
//               <li>
//                 <Link to="/addsecondarysubcategory">
//                   Add Secondary Sub Category
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/managesecondarysubcategory">
//                   Manage Secondary Sub Category
//                 </Link>
//               </li>
//             </ul>
//           </li>
//           <li className="">
//             <a
//               href="#homeSubmenu1"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               {/* New SubCategory */}
//               Expand Focus(nextsecondarysubcategory)
//             </a>
//             <ul className="collapse list-unstyled" id="homeSubmenu1">
//               <li>
//                 <Link to="/addnextsubcategory">Add New Category</Link>
//               </li>
//               <li>
//                 <Link to="/managenextsubcategory">Manage New Category</Link>
//               </li>
//             </ul>
//           </li>
//           <li>
//             <a
//               href="#imageSubmenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               Campus Updates
//               {/* Admission */}
//             </a>
//             <ul className="collapse list-unstyled" id="imageSubmenu">
//               <li>
//                 <Link to="/addadmission">Add Admission</Link>
//               </li>
//               <li>
//                 <Link to="/manageadmission">Manage Admission</Link>
//               </li>
//             </ul>
//           </li>
          
//           <li>
//             <a
//               href="#newsSubmenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               {/* News Post */}
//               Breaking Desk
//             </a>
//             <ul className="collapse list-unstyled" id="newsSubmenu">
//               <li>
//                 <Link to="/addnews">Add News</Link>
//               </li>
//               <li>
//                 <Link to="/managenews">Manage News</Link>
//               </li>
//             </ul>
//           </li>

//           <li className="">
//             <a
//               href="#magzinemenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               {/* Magzine */}
//               Feature Spotlights
//             </a>
//             <ul className="collapse list-unstyled" id="magzinemenu">
//               <li>
//                 <Link to="/addmagzine">Add Magzine</Link>
//               </li>
//               <li>
//                 <Link to="/managemagzine">Manage Magzine</Link>
//               </li>
//             </ul>
//           </li>
//           <li className="">
//             <a
//               href="#videosmenu"
//               data-toggle="collapse"
//               aria-expanded="false"
//               className="dropdown-toggle"
//             >
//               {/* Videos */}
//               Visual Stories
//             </a>
//             <ul className="collapse list-unstyled" id="videosmenu">
//               <li>
//                 <Link to="/addvideos">Add Videos</Link>
//               </li>
//               <li>
//                 <Link to="/managevideos">Manage Videos</Link>
//               </li>
//             </ul>
//           </li>
//           <li>
//             <Link to={`/magazine-subscribers`}> Subscription Vault</Link>
//           </li>
//           {role === "superadmin" && (
//             <li>
//               <Link to="/user-list">User List</Link>
//             </li>
//           )}

//           <li>
//             <Link to={`/author-posts/${userID}`}>Your Tale</Link>
//           </li>

//           {/* 
         

//           <li>
//             <a href="/">Contact</a>
//           </li> */}
//         </ul>

//         <ul className="list-unstyled CTAs d-flex justify-content-around auth-btns">
//           {/* <li>
//             <Link to="/login" type="button" className="btn btn-outline-primary btn-lg" style={{ fontSize: "1rem" }}>Log In</Link>
//           </li> */}
//           {role === "superadmin" && (
//             <li>
//               <Link
//                 to="/register"
//                 type="button"
//                 className="btn btn-lg"
//                 style={{ fontSize: "1rem" }}
//               >
//                 Add User
//               </Link>
//             </li>
//           )}
//         </ul>
//       </nav>
//     </>
//   );
// }

// //   const [sidebarActive, setSidebarActive] = useState(false);

// //   // Function to toggle the sidebar
// //   const toggleSidebar = () => {
// //     console.log('Toggle Sidebar clicked');
// //     setSidebarActive(!sidebarActive);
// //   };


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./dasboard.css";

export default function Sidebar({ sidebarActive }) {
  const [role, setRole] = useState("");
  const [userID, setUserID] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null); // Manages dropdown state

  useEffect(() => {
    setRole(localStorage.getItem("role") || "");
    setUserID(localStorage.getItem("userId") || "");
  }, []);

  // Toggle dropdown menu
  const toggleDropdown = (menu) => {
    setOpenDropdown(openDropdown === menu ? null : menu);
  };

  return (
    <nav id="sidebar" className={sidebarActive ? "active" : ""}>
      <div className="sidebar-header">
        <h3 style={{ fontWeight: "bolder" }}>
          <a href="/">EP Desk</a>
        </h3>
      </div>

      <ul className="list-unstyled components">
        {/* News Realms (Category) */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("homeSubmenu"); }} className="dropdown-toggle">
            News Realms (category)
          </a>
          <ul className={`list-unstyled ${openDropdown === "homeSubmenu" ? "show" : "collapse"}`}>
            <li><Link to="/addcategory">Add Category</Link></li>
            <li><Link to="/managecategory">Manage Category</Link></li>
          </ul>
        </li>

        {/* Focus Zones (Sub Category) */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("subCatSubmenu"); }} className="dropdown-toggle">
            Focus Zones (sub_category)
          </a>
          <ul className={`list-unstyled ${openDropdown === "subCatSubmenu" ? "show" : "collapse"}`}>
            <li><Link to="/addsubcategory">Add Sub Category</Link></li>
            <li><Link to="/managesubcategory">Manage Sub Category</Link></li>
          </ul>
        </li>

        {/* Deep Dives (Secondary Subcategory) */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("secondarysubcategorySubmenu"); }} className="dropdown-toggle">
            Deep Dives (secondarysubcategory)
          </a>
          <ul className={`list-unstyled ${openDropdown === "secondarysubcategorySubmenu" ? "show" : "collapse"}`}>
            <li><Link to="/addsecondarysubcategory">Add Secondary Sub Category</Link></li>
            <li><Link to="/managesecondarysubcategory">Manage Secondary Sub Category</Link></li>
          </ul>
        </li>

        {/* Campus Updates */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("imageSubmenu"); }} className="dropdown-toggle">
            Campus Updates
          </a>
          <ul className={`list-unstyled ${openDropdown === "imageSubmenu" ? "show" : "collapse"}`}>
            <li><Link to="/addadmission">Add Admission</Link></li>
            <li><Link to="/manageadmission">Manage Admission</Link></li>
          </ul>
        </li>

        {/* Breaking Desk */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("newsSubmenu"); }} className="dropdown-toggle">
            Breaking Desk
          </a>
          <ul className={`list-unstyled ${openDropdown === "newsSubmenu" ? "show" : "collapse"}`}>
            <li><Link to="/addnews">Add News</Link></li>
            <li><Link to="/managenews">Manage News</Link></li>
          </ul>
        </li>

        {/* Feature Spotlights */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("magzinemenu"); }} className="dropdown-toggle">
            Feature Spotlights
          </a>
          <ul className={`list-unstyled ${openDropdown === "magzinemenu" ? "show" : "collapse"}`}>
            <li><Link to="/addmagzine">Add Magazine</Link></li>
            <li><Link to="/managemagzine">Manage Magazine</Link></li>
          </ul>
        </li>

        {/* Visual Stories */}
        <li>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleDropdown("videosmenu"); }} className="dropdown-toggle">
            Visual Stories 
          </a>
          <ul className={`list-unstyled ${openDropdown === "videosmenu" ? "show" : "collapse"}`}>
            <li><Link to="/addvideos">Add Videos</Link></li>
            <li><Link to="/managevideos">Manage Videos</Link></li>
          </ul>
        </li>

        {/* User-specific sections */}
        <li><Link to="/magazine-subscribers">Subscription Vault</Link></li>
        {role === "superadmin" && <li><Link to="/user-list">User List</Link></li>}
        <li><Link to={`/author-posts/${userID}`}>Your Tale</Link></li>
      </ul>

      {/* Admin Actions */}
      <ul className="list-unstyled CTAs d-flex justify-content-around auth-btns">
        {role === "superadmin" && (
          <li>
            <Link to="/register" className="btn btn-lg" style={{ fontSize: "1rem" }}>
              Add User
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}
