import React, { useState, useEffect } from "react";
import { Bar, Pie, HorizontalBar, Doughnut } from "react-chartjs-2"; // Import chart types
import Chart from "chart.js/auto"; // Required for Chart.js to work
import Sidebar from "./Sidebar";
import "./dasboard.css";
import axios from "axios";
import moment, { weekdays } from "moment/moment";
import { postCategoryManage } from "../store/Action/categoryAction";
import { useDispatch } from "react-redux";
import AuthorNewsCount from "./AuthorNewsCount";

export default function Dashboard() {
  const [dailyNews, setDailyNews] = useState({
    week: [],
    newsData: [],
    weekdays: [],
  });
  const [todayNews, setTodayNews] = useState([]);
  const [categories, setCategoris] = useState();
  const [day, setDay] = useState("Today");
  const [todayindex, setTodayIndex] = useState(6);
  const dispatch = useDispatch();
  const backgroundColor = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FFD54F",
    "#FFEB3B",
    "#FDD835",
    "#F57F17",
    "#FF7043",
    "#0288D1",
    "#7B1FA2",
    "#388E3C",
    "#FFEB3B",
    "#9E9D24",
    "#FF7043",
    "#64B5F6",
    "#5C6BC0",
    "#26C6DA",
  ];

  async function getSitemapInfo(yyyy, mm, dd) {
    try {
      const apiUrl = process.env.REACT_APP_BACKEND_URL;

      let res;
      if (yyyy && dd && mm) {
        res = await axios.get(
          `${apiUrl}news/site?yyyy=${yyyy}&mm=${mm}&dd=${dd}`
        );
      }
      const sitemap = await res.data;
      return sitemap;
    } catch (error) {
      console.error("Error fetching product list:", error);
      return [];
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await dispatch(
        postCategoryManage({ page: 1, perPage: 100 })
      );
      if (response) {
        setCategoris(response.data.category); // Update with the correct field name from your API response
        // setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    const fetchWeekData = async () => {
      await fetchCategories();
      const week = [];
      const newsData = [];

      for (let i = 0; i < 30; i++) {
        const date = moment().subtract(i, "days");
        const formattedDate = date.format("DD"); // Day (e.g., 19)
        const formattedMonth = date.format("MM"); // Month (e.g., 12)
        const formattedYear = date.format("YYYY");
        const dayName = date.format("dddd"); // Day name (e.g., Monday)

        week.push(`${formattedDate}-${formattedMonth}-${formattedYear}`);
        console.log(formattedYear, formattedMonth, formattedDate, "jnjnjnj");
        // Fetch news data for the date
        const data = await getSitemapInfo(
          formattedYear,
          formattedMonth,
          formattedDate
        );
        console.log(data, "dscewdqwqdwwq"); // Log the data

        if (data && data.newsItems) {
          if (i === 6 - todayindex) {
            setTodayNews(data.newsItems);
          }
          newsData.push(data.newsItems.length); // Push the number of news items for that day
        } else {
          newsData.push(0); // If no data, push 0 (no news)
        }
      }

      // Update state with week and news data
      setDailyNews({ week, newsData, weekdays });
    };
    fetchWeekData();
  }, [todayindex]); // Dependency array will ensure it runs when `todayindex` changes
  console.log(dailyNews, "dailyNews");

  const categoryCounts = todayNews.reduce((acc, news) => {
    // Check if the category already exists in the accumulator
    const existingCategory = acc.find(
      (item) => item.category.name === news.category.name
    );

    if (existingCategory) {
      // If it exists, increment the count
      existingCategory.count += 1;
    } else {
      // If it doesn't exist, add a new category with count 1
      acc.push({ category: news.category, count: 1 });
    }

    return acc;
  }, []);
  console.log(todayNews, "todayNews");

  const leftoverCategories = categories?.filter(
    (category) =>
      !categoryCounts.some((count) => count.category.seoUrl === category.seoUrl)
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        cornerRadius: 4,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#333",
        },
      },
      y: {
        grid: {
          color: "#eaeaea",
        },
        ticks: {
          color: "#333",
          stepSize: 1,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
        barThickness: "flex", // Adjusts bar thickness dynamically
        maxBarThickness: 50, // Max width of a bar
        minBarLength: 2, // Minimum bar length for visibility
      },
    },
  };

  const barData = {
    labels: dailyNews.week,
    datasets: [
      {
        label: "Number of News",
        data: dailyNews.newsData.reverse(),
        backgroundColor: dailyNews.newsData.map((value, index) => {
          const colors = [
            "rgba(75, 192, 87, 0.7)", // Green
            "rgba(192, 87, 87, 0.7)", // Red
            "rgba(87, 87, 192, 0.7)", // Blue
            "rgba(192, 192, 87, 0.7)", // Yellow
            "rgba(192, 87, 192, 0.7)", // Pink
          ];
          return colors[index % colors.length];
        }),
        borderColor: dailyNews.newsData.map((value, index) => {
          const colors = [
            "rgba(75, 192, 87, 1)", // Green
            "rgba(192, 87, 87, 1)", // Red
            "rgba(87, 87, 192, 1)", // Blue
            "rgba(192, 192, 87, 1)", // Yellow
            "rgba(192, 87, 192, 1)", // Pink
          ];
          return colors[index % colors.length];
        }),
        borderWidth: 2,
        borderRadius: 8, // Rounded corners
        barPercentage: 0.8, // Adjust bar width (0.0 - 1.0)
        hoverBackgroundColor: dailyNews.newsData.map((value, index) => {
          const colors = [
            "rgba(75, 192, 87, 1)", // Green
            "rgba(192, 87, 87, 1)", // Red
            "rgba(87, 87, 192, 1)", // Blue
            "rgba(192, 192, 87, 1)", // Yellow
            "rgba(192, 87, 192, 1)", // Pink
          ];
          return colors[index % colors.length];
        }),
      },
    ],
  };

  console.log(dailyNews.week);
  console.log(dailyNews[5]);

  // Data for Pie Chart (Category-wise News)
  const pieData = {
    labels: categoryCounts.map((item) => item.category.name),
    datasets: [
      {
        label: "Category Distribution",
        data: categoryCounts.map((item) => item.count),
        backgroundColor: backgroundColor,
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFD54F",
          "#FFEB3B",
          "#FDD835",
          "#F57F17",
          "#FF7043",
          "#0288D1",
          "#7B1FA2",
          "#388E3C",
          "#FFEB3B",
          "#9E9D24",
          "#FF7043",
          "#64B5F6",
          "#5C6BC0",
          "#26C6DA",
        ],
      },
    ],
  };

  const doughnutData = {
    labels: ["Author A", "Author B", "Author C", "Author D"],
    datasets: [
      {
        label: "Number of Articles",
        data: [10, 15, 8, 20],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="line"></div>
      <div className="card">
        <div className="card-body">
          {/* Bar Chart */}
          <h3>
            <b>Daily News Digest</b>
          </h3>

          <div className="container py-4">
            <div className="row justify-content-between">
              {/* Total News Card */}
              <div className="col-md-4 mb-4">
                <div
                  className="card p-4 text-white shadow-lg"
                  style={{
                    background: "linear-gradient(to right, #38a169, #2f855a)",
                  }}
                >
                  <h3 className="card-title text-center mb-3">Total News</h3>
                  <p className="card-text text-center display-4 font-weight-bold">
                    {todayNews.length}
                  </p>
                </div>
              </div>

              {/* Categories Card */}
              <div className="col-md-4 mb-4">
                <div
                  className="card p-4 text-white shadow-lg"
                  style={{
                    background: "linear-gradient(to right, #3182ce, #2b6cb0)",
                  }}
                >
                  <h3 className="card-title text-center mb-3">Categories</h3>
                  <p className="card-text text-center display-4 font-weight-bold">
                    {categories?.length || 0}
                  </p>
                </div>
              </div>

              {/* Day-Specific News Card */}
              <div className="col-md-4 mb-4">
                <div
                  className="card p-4 text-white shadow-lg"
                  style={{
                    background: "linear-gradient(to right, #9f7aea, #805ad5)",
                  }}
                >
                  <h3 className="card-title text-center mb-3">{day} News</h3>
                  <p className="card-text text-center display-4 font-weight-bold">
                    {todayNews.length}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4 flex-fill bg-white border border-gray-300 rounded-lg shadow-md hover:shadow-lg">
            <div className="text-center mb-3 font-semibold text-gray-700">
              <b>Monthly News Report</b>
            </div>
            <div className="h-96">
              <Bar data={barData} options={options} />
            </div>
          </div>

          <div>
            <div className="row mt-4 gap-2 p-3">
              <div className="col-12 col-md-6 p-2 d-flex flex-column">
                <h3 className="mb-3">
                  <b>Today's News Breakdown by Category</b>
                </h3>
                <div className="chart-box p-3 border flex-grow-1 position-relative">
                  {pieData.datasets[0]?.data.length > 0 ? (
                    // Render the Pie chart if data is available
                    <Pie data={pieData} />
                  ) : (
                    // Show an empty circle with a message when data is missing
                    <div className="empty-pie d-flex flex-column justify-content-center align-items-center">
                      <svg width="150" height="150">
                        <circle
                          cx="75"
                          cy="75"
                          r="70"
                          fill="none"
                          stroke="#ddd"
                          strokeWidth="5"
                        />
                      </svg>
                      <p className="text-muted mt-2">
                        No articles posted today
                      </p>
                    </div>
                  )}
                  <div className="d-flex gap-2 justify-content-center mt-5 pie-chart-btn-list flex-wrap">
                    {dailyNews.week.reverse().map((item, i) => (
                      <button
                        key={i}
                        className={`btn ${
                          todayindex === i ? "btn-danger" : "btn-info"
                        }`}
                        onClick={() => {
                          setTodayIndex(i);
                          setDay(item);
                        }}
                      >
                        {item.slice(0, 2)}{" "}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Empty Categories Section */}
              <div className="col-12 col-md-5 p-2 d-flex flex-column">
                <h3 className="mb-3">
                  <b> Empty Categories {day}</b>
                </h3>
                <div className="chart-box p-3 border flex-grow-1">
                  <ul>
                    {leftoverCategories?.map((item, i) => (
                      <li style={{ color: backgroundColor[i] }} key={i}>
                        <b> {item.displayName} </b>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <AuthorNewsCount backgroundColor={backgroundColor} />
          </div>
        </div>
      </div>
    </>
  );
}
