import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./dasboard.css";

export default function AuthorNewsCount() {
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [authorData, setAuthorData] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  async function getAuthorInfo(date = null) {
    try {
      const res = await axios.get(`${apiUrl}news/real/author`, {
        params: date ? { date } : {}, // Add date query parameter if provided
      });

      const author = res.data;
      console.log("Fetched author data:", author);
      setAuthorData(author);

      return author;
    } catch (error) {
      console.error(
        "Error fetching author list:",
        error.response?.data || error.message
      );
      return [];
    }
  }

  useEffect(() => {
    getAuthorInfo(date);
  }, [date]);

  console.log(date, authorData, "authorData");

  return (
    <div className="container p-4 bg-white border border-gray-300 rounded-lg shadow-md hover:shadow-lg">
      <div className="row justify-content-between align-items-center mb-3">
        <div className="col-md-6">
          <span className="fs-3">
            <b>Date:</b> {authorData.date}
          </span>
        </div>
        <div className="col-md-6 text-md-end text-start">
          <input
            type="date"
            value={date}
            className="p-2 fw-bolder"
            max={moment().format("YYYY-MM-DD")}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div>

      {/* Cards Section */}
      <div className="row g-4">
        {authorData?.newsCountByAuthor?.map((item, i) => (
          <div className="col-md-4 col-sm-6 col-12" key={i}>
            <div
              className="card p-4 text-white shadow-lg text-center"
              style={{
                background: "linear-gradient(45deg, #1b0167, #000)",
                color: "white",
              }}
            >
              <h3
                className="card-title mb-3"
                style={{
                  color: "white",
                }}
              >
                {item.authorName}
              </h3>
              <p
                className="card-text display-4 font-weight-bold"
                style={{
                  color: "white",
                }}
              >
                {item.totalNewsCount}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
