import "./video.css";

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./video.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  deleteVideo,
  postVideoUpdate,
} from "../../../store/Action/videoAction";
import axios from "axios";

function ManageVideos() {
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletes, setDelete] = useState(1);
  const itemsPerPage = 10; // Change this to the desired number of items per page

  // Logic to calculate total pages
  const queryParams = { page: currentPage, perPage: itemsPerPage };

  // Fetch categories and total count from API or any data source
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${apiUrl}videos`);
        if (response) {
          console.log(response, "videossssss");
          setVideos(response.data); // Update with the correct field name from your API response
          console.log(response.data, videos, "video");
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [dispatch, currentPage, deletes]);

  console.log(videos, "poiy");
  // Logic to handle pagination controls
  const nextPage = () => {
    if (videos.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleDelete = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const response = dispatch(deleteVideo(categoryId));
      if (response) {
        setDelete(response);
      }
    }
  };
  return (
    <div className="manage-category">
      <div className="card">
        <div className="card-header">Manage Video</div>
        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Video Order</th>
                <th scope="col">Video Headline</th>
                <th scope="col">Video URL</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {videos.map((video) => (
                <tr key={video._id}>
                  <td>{video.order}</td>
                  <td>{video.title}</td>
                  <td>{video.video_link}</td>
                  <td>
                    <Link to={`/editvideos/${video._id}`}>
                      <button className="action-button">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>

                    <button
                      className="action-button"
                      onClick={() => handleDelete(video._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
            <div className="pagination">
              <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="current-page">{currentPage}</span>

              <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={videos.length < itemsPerPage}
              >
                {videos.length < itemsPerPage ? "" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageVideos;
